import { template as template_5befd7399f604c70aab4fd83db22b23e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_5befd7399f604c70aab4fd83db22b23e(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
