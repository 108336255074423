import { template as template_356b176e2ae54eb0a859bb6acb69c6bc } from "@ember/template-compiler";
const FKLabel = template_356b176e2ae54eb0a859bb6acb69c6bc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
