import { template as template_72bb1391a6664c2ebcfe20b7edb2c1d5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_72bb1391a6664c2ebcfe20b7edb2c1d5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
