import { template as template_b36c7d0115d54a6eb6a81174f11bd3ae } from "@ember/template-compiler";
const SidebarSectionMessage = template_b36c7d0115d54a6eb6a81174f11bd3ae(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
