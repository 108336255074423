import { template as template_973b95dbe1e54edb9982745b82325afd } from "@ember/template-compiler";
const WelcomeHeader = template_973b95dbe1e54edb9982745b82325afd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
