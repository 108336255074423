import { template as template_2d7503e9f4964ff79fbc0167664af201 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2d7503e9f4964ff79fbc0167664af201(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
