import { template as template_b8612f4e2a1d449aa2fc00c069c177c2 } from "@ember/template-compiler";
const FKText = template_b8612f4e2a1d449aa2fc00c069c177c2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
